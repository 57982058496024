import { gql } from 'graphql-request';

import PrimaryVisualsFragment from '../PrimaryVisuals/PrimaryVisualsFragment';

const ContributorFragment = gql`
  order 
  roles {
    slug
  }
  profile {
    resourceType
    firstName
    lastName
    title
    jobTitle
    canonicalSlug
    description
    descriptionText
    profileRelatedLinks {
      description
      uri
    }
    primaryVisuals {
     ${PrimaryVisualsFragment} 
    }
  }
`;

export default ContributorFragment;
