import ImageShapeType, { imageShape } from 'types/ImageShapeType';

const ImageShape: ImageShapeType = {
  normal: { name: imageShape.NORMAL, width: 400, height: 300 },
  portrait: { name: imageShape.PORTRAIT, width: 300, height: 400 },
  square: { name: imageShape.SQUARE, width: 250, height: 250 },
  squaresmall: { name: imageShape.SQUARE, width: 200, height: 200 },
  squaresmaller: { name: imageShape.SQUARE, width: 150, height: 150 },
  uncropped: { name: imageShape.UNCROPPED, width: 300, height: 300 },
  widescreen: { name: imageShape.WIDESCREEN, width: 400, height: 225 }
};

export default ImageShape;
