'use client';
import React, { useState, useEffect, useRef } from 'react';
import sty from 'styles/modules/FeaturedSongAndAlbum.module.css';
import Link from 'next/link';
import { Image } from '@apmg/mimas';
import { getItemLink } from 'v1/utils';
import Loading from 'components/Loading/Loading';
import { fetchHomeSongAlbum } from 'server/Home/fetchHomeSongAlbum';
import CmsContentType from 'models/Content/CmsPostType';

type FeaturedProps = {
  artist?: string;
  song?: string;
  album?: string;
};

const parseTitleByHyphen = (title: string) => {
  const titleArray = title.match(/-/) ? title.split(' - ') : '';
  const titleObject = {
    artist: titleArray[0],
    song: titleArray[1]
  };
  return titleObject;
};

const parseTitleByColon = (title: string) => {
  const titleArray = title && title.match(/:/) ? title.split(':') : '';
  const parsedTitle = titleArray[1].match(/,/) ? titleArray[1].split(',') : '';
  const titleObject = {
    artist: parsedTitle[1].replace(/['"]+/g, '').trimStart(),
    album: parsedTitle[0].trimStart()
  };
  return titleObject;
};

const FeaturedSongAndAlbum = () => {
  const [songOfTheDay, setSongOfTheDay] = useState<CmsContentType>();
  const [parsedSongOfTheDay, setParsedSongOfTheDay] = useState<FeaturedProps>();
  const [albumOfTheWeek, setAlbumOfTheWeek] = useState<CmsContentType>();
  const songRef = useRef(null);

  let parsedAlbumOfTheWeek;
  let featuredAlbumLink;

  function parseSongOfTheDay(post: CmsContentType) {
    const parsed = post.title.match(/-/)
      ? parseTitleByHyphen(post.title)
      : (post.title as unknown as FeaturedProps);
    return parsed;
  }

  if (albumOfTheWeek) {
    parsedAlbumOfTheWeek = albumOfTheWeek.title;
    featuredAlbumLink = getItemLink(albumOfTheWeek).href;
  }

  async function getData() {
    if (songOfTheDay) return;
    const data = await fetchHomeSongAlbum();
    if (data?.props?.song) setSongOfTheDay(data.props.song);
    if (data?.props?.song)
      setParsedSongOfTheDay(parseSongOfTheDay(data.props.song));
    if (data?.props?.album) setAlbumOfTheWeek(data.props.album);
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        getData();
      }
    });
    if (songRef.current) observer.observe(songRef.current);
    return () => {
      if (songRef.current) observer.unobserve(songRef.current);
    };
  }, [songOfTheDay]);

  return (
    <div ref={songRef} className={sty.featuredSongAlbum}>
      <div className="container">
        <section>
          <div className={sty.featuredSongAndAlbumContainer}>
            {songOfTheDay ? (
              <div className={sty.featured}>
                <Link
                  href={getItemLink(songOfTheDay).href}
                  prefetch={false}
                  passHref
                >
                  <Image
                    image={songOfTheDay.primaryVisuals.thumbnail}
                    aspectRatio="normal"
                    alt={songOfTheDay.primaryVisuals.thumbnail?.shortCaption}
                    loading="lazy"
                  />
                </Link>
                <div className={sty.featured_content}>
                  <div className={sty.featured_badge}>
                    <Link
                      href={getItemLink(songOfTheDay).href}
                      prefetch={false}
                    >
                      Song of the Day
                    </Link>
                  </div>

                  {parsedSongOfTheDay && (
                    <>
                      <div className={sty.featured_artist}>
                        {parsedSongOfTheDay.artist}
                      </div>
                      <div className={sty.featured_title}>
                        {parsedSongOfTheDay.song}
                      </div>
                    </>
                  )}

                  <div className={sty.featured_description}>
                    {songOfTheDay.descriptionText}
                  </div>
                  <div className={sty.featured_link}>
                    <Link
                      href={getItemLink(songOfTheDay).href}
                      prefetch={false}
                    >
                      Get Today's Song
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <Loading />
            )}
            {albumOfTheWeek ? (
              <div className={sty.featured}>
                {featuredAlbumLink && (
                  <>
                    <div className={sty.featured_image}>
                      <Link href={featuredAlbumLink} prefetch={false} passHref>
                        <Image
                          image={albumOfTheWeek.primaryVisuals.thumbnail}
                          aspectRatio="normal"
                          alt={
                            albumOfTheWeek.primaryVisuals.thumbnail
                              ?.shortCaption
                          }
                          loading="lazy"
                        />
                      </Link>
                    </div>
                    <div className={sty.featured_content}>
                      <div className={sty.featured_badge}>
                        <Link href={featuredAlbumLink} prefetch={false}>
                          Featured
                        </Link>
                      </div>
                      {parsedAlbumOfTheWeek && (
                        <>
                          <div className={sty.featured_artist}>
                            {albumOfTheWeek.title}
                          </div>
                          <div className={sty.featured_description}>
                            {albumOfTheWeek.descriptionText}
                          </div>
                          <div className={sty.featured_link}>
                            <Link href={featuredAlbumLink} prefetch={false}>
                              Read More
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <Loading />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default FeaturedSongAndAlbum;
