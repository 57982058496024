import React, { FC } from 'react';
import sty from 'styles/modules/Loading.module.css';

interface Props {
  defaultHeight?: boolean;
}

const Loading: FC<Props> = ({ defaultHeight = true }) => {
  return (
    <div
      className={`${sty.loaderContainer} ${
        defaultHeight ? sty.defaultHeight : sty.customHeight
      }`}
    >
      <div className={sty.spinner}></div>
    </div>
  );
};

export default Loading;
