export default interface ImageShapeType {
  normal: Dimensions;
  portrait: Dimensions;
  square: Dimensions;
  squaresmall: Dimensions;
  squaresmaller: Dimensions;
  uncropped: Dimensions;
  widescreen: Dimensions;
}

export interface Dimensions {
  name: imageShape;
  height: number;
  width: number;
}

export enum imageShape {
  NORMAL = 'normal',
  PORTRAIT = 'portrait',
  SQUARE = 'square',
  SQUARESMALL = 'squaresmall',
  UNCROPPED = 'uncropped',
  WIDESCREEN = 'widescreen'
}
