import { cache } from 'react';
import fetcher from 'utils/fetcher';
import COLLECTIONQUERY from 'models/Collection/CollectionQuery';
import CollectionType from 'models/Collection/CollectionType';

export const fetchHomeSongAlbum = cache(async () => {
  let errorCode = null;

  try {
    const [songs, albums] = await Promise.all([
      fetcher<CollectionType>(COLLECTIONQUERY, {
        contentAreaSlug: process.env.NEXT_PUBLIC_CONTENT_AREA_SLUG,
        slug: 'song-of-the-day'
      }),
      fetcher<CollectionType>(COLLECTIONQUERY, {
        contentAreaSlug: process.env.NEXT_PUBLIC_CONTENT_AREA_SLUG,
        slug: 'featured'
      })
    ]);

    return {
      props: {
        song: songs.collection.results.items[0],
        album: albums.collection.results.items[0],
        errorCode
      }
    };
  } catch (err) {
    console.error(`❌ Error ${err} `);
    errorCode = 404;
  }
});
